<template>
  <HeadlessCombobox v-if="filterData" v-model="filter.type" multiple>
    <AuctionButtonsContainer static>
      <AuctionListFilterButton as="button" @click="toggleAll">
        {{ $t('auction-list.all') }}
      </AuctionListFilterButton>
      <AuctionListFilterButton
        v-for="type in filterData.types"
        :key="type"
        :selected="filter.type.includes(type)"
        :value="type"
      >
        {{ type.toLowerCase() }}
      </AuctionListFilterButton>
    </AuctionButtonsContainer>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import AuctionListFilterButton from './AuctionListFilterButton.vue'
import AuctionButtonsContainer from './AuctionButtonsContainer.vue'

const filterData = inject('filterData') as Ref<ParsedFilterData>

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const toggleAll = () => {
  filter.value.type =
    filter.value.type.length === filterData.value.types.length
      ? []
      : filterData.value.types
}
</script>
